import React from "react";
import Header from "../../../common/header";
import Header2 from "../../../common/header/Header2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as SearchWhiteIcon} from "./profile_icon/search-white-icon.svg";
import {ReactComponent as NotificationIcon} from "./profile_icon/notification-icon.svg";

import {ReactComponent as ProfileIcon} from "./profile_icon/profile-icon.svg";
import "./profile.css";
import TabHook from "../../../common/hooks/TabHook";
import {BottomNavigation, BottomNavigationAction, useMediaQuery} from "@mui/material";
import {getAlertCountAPI, getTop7DayPerformerAPI, updateAccount} from "../../../apihelper/profile";
import {getNormalUserData, getUserReward} from "../../../apihelper/portfolio";
import TransactionCard2 from "../reward_trasaction_history/TransactionCard2";
import json2mq from "json2mq";
import MyPortfolioTable from "./tables/MyPortfolioTable";
import {Day7SummaryTable} from "./tables/Day7SummaryTable";
import CryptoWatchlist from "./tables/CryptoWatchlist";
import MyPortfolioWatchlist from "./tables/MyPortfolioWatchlist";
import {Helmet} from "react-helmet";

const Account = () => {
    const navigate = useNavigate();
    const [rewards, setRewards] = React.useState(null);
    const [error, setError] = React.useState(false);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const handleBackClick = () => navigate(-1);

    const [snackbaropen, setSnackbaropen] = React.useState(false);
    const [severity, setSeverity] = React.useState("info");
    const [snackbarmessage, setSnackbarmessage] = React.useState("Loading...");
    const [fullName, setFullName] = React.useState("");
    const [profilePhoto, setProfilePhoto] = React.useState();
    const [walletAddress, setWalletAddress] = React.useState("");
    const [copied, setCopied] = React.useState(false);
    const [youtubeLink, setYoutubeLink] = React.useState("");
    const [twitterLink, setTwitterLink] = React.useState("");
    const [telegramLink, setTelegramLink] = React.useState("");
    const [instagramLink, setInstagramLink] = React.useState("");

    const [isEditingTwitter, setIsEditingTwitter] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [description, setDescription] = React.useState("");
    const [isSaved, setIsSaved] = React.useState(false);
    const [userReward, setUserReward] = React.useState({});
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [errorReward, setErrorReward] = React.useState(false);

    const [unreadAlerts, setUnreadAlerts] = React.useState(0);
    const [top7DayPerformer, setTop7DayPerformer] = React.useState([]);

    const location = useLocation();
    const loc = location.pathname;

    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );
    const headerTabOptions = [
        {
            id: 1,
            text: "Go To Home Page",
            route: "/",
            icon: "home-icon.svg",
            color: "#FFB45C",
        },
        {
            id: 2,
            text: "Crypto Screener",
            route: "/crypto-screener",
            icon: "search-icon.svg",
            color: "#D2FD8B",
        },
        {
            id: 3,
            text: "Price Predictions",
            route: "/crypto-predictions",
            icon: "prediction-page-icon.svg",
            color: "#FFE144",
        },
        {
            id: 4,
            text: "Audit My Portfolio",
            route: userData ? "/my-portfolio" : "/audit-my-portfolio",
            icon: "audit-portfolio-icon.svg",
            color: "#48CEFF",
        },
    ];

    const profileTabList = [
        {
            title: (
                <div className="profile-tab-div d-flex align-items-center">
                    <img
                        src={"/images/profile_image/account_image/gallery-icon.svg"}
                        alt="Profile Icon"
                        className="profile-tab-icon"
                    />
                    <span>My Profile</span>
                </div>
            ),
            url: "/account",
        },
        {
            title: (
                <div className="profile-tab-div">
                    <img
                        src={"/images/profile_image/account_image/invest-icon.svg"}
                        alt="Profile Icon"
                        className="profile-tab-icon"
                    />
                    <span>Invest In Presale</span>
                </div>
            ),
            url: "/pre-sale",
        },
        {
            title: (
                <div className="profile-tab-div">
                    <img
                        src={"/images/profile_image/account_image/search-white-icon.svg"}
                        alt="Profile Icon"
                        className="profile-tab-icon"
                    />
                    <span>Farm $WISD</span>
                </div>
            ),
            url: "/reward-transaction-history",
        },
    ];

    const handleNameChange = (e) => {
        setFullName(e.target.value);
    };

    const handleSaveClick = () => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const user_id = userData.user_id;
        const token = userData.token;

        updateAccount({
            user_id: user_id,
            fullName,
            profilePhoto,
            about: description,
            youtube: youtubeLink,
            twitter: twitterLink,
            telegram: telegramLink,
            instagram: instagramLink,
            wallet_address: walletAddress,
            token: token,
        }).then((res) => {
            if (res.code === "200") {
                setFullName(res.data.full_name);
                setDescription(res.data.description);
                setTwitterLink(res.data.twitter_link);
                setProfilePhoto(res.data.photo);
                setWalletAddress(res.data.wallet_address);
            } else {
                alert(res.message);
            }
        });
    };

    const handleTwitterChange = (e) => {
        setTwitterLink(e.target.value);
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };
    const handleSave = () => {
        if (walletAddress) {
            const user_id = userData.user_id;
            const token = userData.token;

            updateAccount({
                user_id: user_id,
                fullName,
                profilePhoto,
                about: description,
                youtube: youtubeLink,
                twitter: twitterLink,
                telegram: telegramLink,
                instagram: instagramLink,
                wallet_address: walletAddress,
                token: token,
            }).then((res) => {
                if (res.code === "200") {
                    userData.full_name = fullName;
                    userData.wallet_address = walletAddress;

                    setFullName(res.data.full_name);
                    setDescription(res.data.description);
                    setTwitterLink(res.data.twitter_link);
                    setProfilePhoto(res.data.photo);
                    setWalletAddress(res.data.wallet_address);
                } else {
                    alert(res.message);
                }
            });
            setIsSaved(true);
        }
    };
    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        setProfilePhoto(file);
    };
    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };
    const handleInputChange = (e) => {
        setWalletAddress(e.target.value);
        setIsSaved(false);
    };
    const generateReferralLink = () => {
        const formattedFullName = fullName.replace(/\s+/g, "-");
        return `https://investing.crowdwisdom.live/signUp?ref=${formattedFullName}`;
    };
    const handleButtonSaveClick = () => {
        setSnackbaropen(true);
        setSeverity("info");
        setSnackbarmessage("Loading...");
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const user_id = userData?.user_id || "";
        const token = userData.token;
        const about = description;
        const wallet_address = walletAddress;
        const twitter = twitterLink;
        updateAccount({user_id, fullName, profilePhoto, token, about, wallet_address, twitter}).then((res) => {
            if (res.code === "200") {
                setSnackbarmessage(res.message);
                setFullName(res.data.full_name);
                setDescription(res.data.description);
                setTwitterLink(res.data.twitter_link);
                setProfilePhoto(res.data.photo);
                setWalletAddress(res.data.wallet_address);
                localStorage.setItem("cw_portfolio_user", JSON.stringify(userData));
            } else {
                setSeverity("error");
                setSnackbarmessage(res.message);
            }
        });

        handleDialogClose();
    };
    const handleTwitterEditClick = (e) => {
        e.preventDefault();
        setIsEditingTwitter(true);
    };
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };
    const handleTwitterSaveClick = (e) => {
        e.preventDefault();
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const user_id = userData.user_id;
        const token = userData.token;

        updateAccount({
            user_id: user_id,
            fullName,
            about: description,
            profilePhoto,
            youtube: youtubeLink,
            twitter: twitterLink,
            telegram: telegramLink,
            instagram: instagramLink,
            wallet_address: walletAddress,
            token: token,
        }).then((res) => {
            if (res.code === "200") {
                setFullName(res.data.full_name);
                setDescription(res.data.description);
                setTwitterLink(res.data.twitter_link);
                setProfilePhoto(res.data.photo);
                setWalletAddress(res.data.wallet_address);
            } else {
                alert(res.message);
            }
        });
    };
    const handleButtonClick = () => {
        setIsDialogOpen(true);
    };
    const fetchUserRewards = async () => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        try {
            const data = await getUserReward(userData);
            setErrorReward(false);
            setUserReward(data);
        } catch (error) {
            errorReward(true);
            console.error("Error fetching rewards:", error);
        }
    };

    React.useEffect(() => {
        const userId = userData?.user_id || null;

        const fetchPerformer = async () => {
            const response = await getTop7DayPerformerAPI(userData);
            setTop7DayPerformer(response.data);
        };

        fetchPerformer();
    }, []);

    React.useEffect(() => {
        const userId = userData?.user_id || null;

        const fetchAlerts = async () => {
            const response = await getAlertCountAPI(userData?.user_id);
            if (response?.unread_notifications){
                setUnreadAlerts(response?.unread_notifications);
            }
        };

        fetchAlerts();
    }, []);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
                if (!userData) {
                    throw new Error("No user data found in local storage");
                }

                const user_idValue = userData.user_id;
                const user_token = userData.token;
                const userRes = await getNormalUserData({
                    user_id: user_idValue,
                    Token: user_token,
                });

                if (userRes.code === "200") {
                    fetchUserRewards();
                    const userData = userRes.data[0];
                    setFullName(userRes.data[0].full_name);
                    setDescription(userRes.data[0].description);
                    setTwitterLink(userRes.data[0].twitter_link);
                    setProfilePhoto(userRes.data[0].photo);
                    setWalletAddress(userRes.data[0].wallet_address);
                } else {
                    console.error(userRes.message);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);

    React.useEffect(() => {
        const fetchRewards = async () => {
            try {
                const response = await fetch("/api/user/rewards");
                const data = await response.json();
                setRewards(data.rewards);
            } catch (error) {
                setError(true);
            }
        };
        fetchRewards();
    }, []);

    return (
        <>
            <div className="profile-main">
                <div className="d-flex row  w-100 m-0">
                    <Helmet>
                        <title>Profile</title>
                    </Helmet>
                    <div className=" is-mobile mt-3">
                        <div className=" d-flex justify-content-between">
                            <div className="d-flex justify-content-center reward-container">
                                <a
                                    href="#"
                                    className="rounded-lg px-3 py-2 my-auto d-flex align-items-center justify-content-between airdrop-btn w-auto"
                                >
                                    <img src="images/profile_image/account_image/airdrop-icon.svg" />
                                    <span className="airdrop-btn-text">
                                        Next Airdrop <br />
                                        17-04-2025
                                    </span>
                                </a>
                            </div>
                            <div className="d-flex profile-left-section">
                                <div className=" my-profile-container">
                                    <a
                                        href="#"
                                        className="rounded-pill px-3 py-2 d-flex align-items-center justify-content-between my-profile-btn w-auto"
                                    >
                                        <ProfileIcon />
                                        {/* <span className="tab-text">My Profile</span> */}
                                    </a>
                                </div>
                                <div
                                    className="notification-section position-relative"
                                    onClick={() => navigate("/alerts")}
                                >
                                    <NotificationIcon className="notification-icon fa-2x" />
                                    {unreadAlerts > 0 && (
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                            {unreadAlerts}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Side Bar */}
                    {/* {mediaQueryVar ? null : ( */}
                    <Header2>
                        <div onClick={() => handleBackClick()}>
                            <ArrowBackIcon />
                        </div>
                    </Header2>
                    {/* )} */}
                    <div className="profile-section col" style={{maxWidth: "100%"}}>
                        <div className="profile-container  profile-container-lg">
                            {/* Header Tabs */}
                            {mediaQueryVar ? (
                                <div className="header-tabs d-flex justify-content-between">
                                    {headerTabOptions.map((option) => (
                                        <a
                                            key={option.id}
                                            href={option.route}
                                            className="rounded-pill px-3 py-2 d-flex align-items-center "
                                            style={{backgroundColor: option.color}}
                                        >
                                            <img src={`images/profile_image/account_image/${option.icon}`} />
                                            <span className="tab-text pl-1">{option.text}</span>
                                        </a>
                                    ))}
                                </div>
                            ) : null}

                            {/* Profile tabs */}
                            <div className="d-flex justify-content-between my-5">
                                {mediaQueryVar ? (
                                    <>
                                        <TabHook activeTab={0} tabList={profileTabList} />
                                        <div className="d-flex profile-left-section">
                                            <div className=" my-profile-container">
                                                <a
                                                    href="#"
                                                    className="rounded-pill px-3 py-2 d-flex align-items-center justify-content-between my-profile-btn w-auto"
                                                >
                                                    <ProfileIcon />
                                                    {/* <span className="tab-text">My Profile</span> */}
                                                </a>
                                            </div>
                                            <div
                                                className="notification-section position-relative"
                                                onClick={() => navigate("/alerts")}
                                            >
                                                <NotificationIcon className="notification-icon fa-2x" />
                                                {unreadAlerts > 0 && (
                                                    <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                                        {unreadAlerts}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <TabHook activeTab={0} tabList={profileTabList} />
                                )}
                            </div>

                            {/* Search bar */}
                            {/* <div className="search-container search-section mt-5">
                            <img
                                src={"/images/profile_image/account_image/search-blue-icon.svg"}
                                alt="Search Icon"
                                className="search-icon"
                            />

                            <input type="text" placeholder="Search Portfolios, Predictions Cryptos" />
                        </div> */}

                            <div className="input-container row mt-5 order-2  order-md-2">
                                <div className="address-input col-12 col-md-6 mb-3">
                                    <input
                                        style={{
                                            paddingRight: "58px",
                                        }}
                                        type="text"
                                        id="userInput"
                                        placeholder="Enter Your Solana Wallet Address"
                                        value={walletAddress}
                                        onChange={handleInputChange}
                                    />
                                    <button onClick={handleSave}>Save</button>
                                </div>
                                <div className="address-input col-12 col-md-6 mb-3">
                                    <input
                                        style={{
                                            paddingRight: copied ? "70px" : "63px",
                                        }}
                                        type="text"
                                        placeholder="Your Referral Link"
                                        value={generateReferralLink()}
                                        readOnly
                                        onFocus={(e) => (e.target.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.2)")}
                                        onBlur={(e) => (e.target.style.boxShadow = "0px 2px 6px rgba(0, 0, 0, 0.1)")}
                                    />
                                    <button
                                        onClick={() => {
                                            navigator.clipboard.writeText(generateReferralLink());
                                            setCopied(true);
                                            setTimeout(() => setCopied(false), 2000);
                                        }}
                                        onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                                        onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                                    >
                                        {copied ? "Copied!" : "Copy"}
                                    </button>
                                </div>
                                <div className="address-input col-12 col-md-6 mb-3">
                                    <input
                                        style={{
                                            paddingRight: "58px",
                                        }}
                                        type="text"
                                        placeholder="Your TG Address For Farming"
                                    />
                                    <button>Save</button>
                                </div>
                            </div>

                            {/* Rewards Section */}
                            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between text-center text-md-start mb-5 mt-5 reward-container">
                                {/* Transaction Card - Moves below Airdrop in mobile */}
                                <div className="w-100">
                                    <TransactionCard2 />
                                </div>

                                <div className=" is-desktop">
                                    <div className="airdrop-container  mb-3 mb-md-0 d-flex">
                                        <a
                                            href="#"
                                            className="rounded-lg px-3 py-2 my-auto d-flex align-items-center justify-content-between airdrop-btn w-auto"
                                        >
                                            <img src="images/profile_image/account_image/airdrop-icon.svg" />
                                            <span className="airdrop-btn-text">
                                                Next Airdrop <br />
                                                17-04-2025
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            {/* Top gainer */}
                            <div className="d-flex flex-column flex-md-row justify-content-between gap-3 mt-5">
                                {top7DayPerformer?.seven_days?.length > 0 && top7DayPerformer?.thirty_days?.length > 0 && (
                                    <>
                                        <div className="d-flex justify-content-around top-gainer-card w-100">
                                            <p>Top Performer 7 days</p>
                                            <img
                                                src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${top7DayPerformer?.seven_days[0]?.crypto_id}.png`}
                                                alt="Gainer Icon"
                                            />
                                            <p>{top7DayPerformer?.seven_days[0]?.slug}</p>
                                        </div>

                                        <div className="d-flex justify-content-around top-gainer-card w-100">
                                            <p>Top Performer 30 days</p>
                                            <img
                                                src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${top7DayPerformer?.thirty_days[0]?.crypto_id}.png`}
                                                alt="Gainer Icon"
                                            />
                                            <p>{top7DayPerformer?.thirty_days[0]?.slug}</p>
                                        </div>
                                    </>
                                )}
                            </div>

                            {/* My Portfolio */}
                            <div className="section-1">
                                <div className="profile-headings mt-5">
                                    <h1>MY PORTFOLIOS</h1>
                                </div>
                                <MyPortfolioTable />
                            </div>

                            <Day7SummaryTable />
                            {/* </div> */}

                            {/* Crypto Watchlist */}
                            {/* <div className="section-3 mt-5">
                            <div className="profile-headings mt-5">
                                <h1>CRYPTO WATCHLIST</h1>
                            </div>
                            <div className="add-crypto-watchlist-button d-flex justify-content-center mt-3">
                                <button className="d-flex align-items-center ">
                                    <span>ADD MORE CRYPTOS</span>
                                    <img
                                        src="images/profile_image/account_image/left-arrow-icon.svg"
                                        className="left-arrow-icon"
                                    />
                                </button>
                            </div>
                            <CryptoWatchlist />
                        </div> */}

                            {/* Portfolio Watchlist */}
                            <MyPortfolioWatchlist />
                        </div>
                    </div>
                </div>
            </div>
            {!mediaQueryVar && (
                <div
                    style={{
                        position: "fixed",
                        bottom: "0",
                        backgroundColor: "white",
                        width: "105%",
                        zIndex: "100",
                        marginLeft: "-10px",
                    }}
                >
                    <BottomNavigation showLabels>
                        <BottomNavigationAction
                            label="Top Portfolios"
                            onClick={() => navigate("/portfolio-gallery")}
                            icon={
                                <img
                                    src={
                                        loc === "/portfolio-gallery"
                                            ? "/images/dashboardS.svg"
                                            : "/images/dashboardU.svg"
                                    }
                                />
                            }
                        />
                        <BottomNavigationAction
                            label="My Portfolios"
                            onClick={() => {
                                navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
                            }}
                            icon={
                                <img
                                    src={loc === "/my-portfolio" ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}
                                />
                            }
                        />
                        <BottomNavigationAction
                            label="Subscription"
                            onClick={() => {
                                navigate("/subscriptions");
                            }}
                            icon={
                                <img
                                    src={
                                        loc === "/subscriptions/screener360"
                                            ? "/images/dollarS.svg"
                                            : "/images/dollarU.svg"
                                    }
                                    width={24}
                                    height={24}
                                    alt="Icon"
                                    style={{marginBottom: "-3px"}}
                                />
                            }
                        />
                    </BottomNavigation>
                </div>
            )}
        </>
    );
};

export default Account;
