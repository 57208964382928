import React, {useState, useEffect, useCallback} from "react";
import TableHook from "./TableHook";
import {useNavigate} from "react-router-dom";
import TableCardHook from "./TableCardHook";
import {getPortfolioForGalleryWatchlist} from "../../../../apihelper/homepage";
import {LoadingButton, Skeleton} from "@mui/lab";

const MyPortfolioWatchlist = () => {
    const navigate = useNavigate();
    const [header, setHeader] = useState([
        {label: "Portfolio Name", key: "portfolio_name", sorting: "asc"},
        {label: "Last 7 Days", key: "seven_day", sorting: "asc"},
        {label: "Last 30 Days", key: "thirty_day", sorting: "asc"},
        {label: "Last 6 Months", key: "six_month", sorting: "asc"},
        {label: "", key: "action"},
    ]);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const [photoIndex, setPhotoIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [myPortfolio, setMyPortfolio] = useState([]);
    const [filter, setFilter] = useState("");
    const [photoGalleryLoader, setPhotoGalleryLoader] = useState(true);
    const [addMoreLoader, setAddMoreLoader] = useState(false);
    const [sortOrder, setSortOrder] = useState({
        portfolio_name: "asc",
        seven_day: "asc",
        thirty_day: "asc",
        six_month: "asc",
    });
    const [sortKey, setSortKey] = useState("portfolio_name");
    const [sortType, setSortType] = useState("pn_asc");

    const loadMoreGallery = () => {
        setPhotoIndex((prevIndex) => prevIndex + 7);
        setAddMoreLoader(true);
        getPortfolioForGalleryWatchlist({
            user_id: userData?.user_id,
            search_text: filter,
            start_index: photoIndex + 7,
            limit: "7",
        }).then((res) => {
            if (res.code === "200") {
                setMyPortfolio((prevPortfolio) => [...prevPortfolio, ...res.data]);
                setPhotoGalleryLoader(res.data.length >= 7);
                setAddMoreLoader(false);
            } else {
                console.error("Data fetching error");
                setAddMoreLoader(false);
            }
        });
    };

    const fetchData = useCallback(() => {
        setLoading(true);
        getPortfolioForGalleryWatchlist({
            user_id: userData?.user_id,
            search_text: filter,
            start_index: String(photoIndex),
            limit: "7",
        }).then((res) => {
            if (res.code === "200") {
                setMyPortfolio(res.data);
                setLoading(false);
                setPhotoGalleryLoader(res.data.length >= 7);
            } else {
                console.error("Data fetching error");
                setLoading(false);
            }
        });
    }, [userData, filter, photoIndex]);

    useEffect(() => {
        fetchData();
    }, []);

    const row = myPortfolio.map(
        ({
            portfolio_name,
            portfolio_id,
            seven_day_roi: sevenDays,
            thirty_day_roi: thirtyDays,
            six_month_roi: sixMonths,
            photo,
        }) => [
            <div key={portfolio_name} className="table-col-1 d-flex">
                <div>
                    {photo ? (
                        <img
                            width={39.77}
                            height={38}
                            src={window.constants.asset_path + photo}
                            style={{
                                borderRadius: "19px",
                                border: "2px solid black",
                            }}
                        />
                    ) : (
                        <img
                            width={39.77}
                            height={38}
                            src={window.constants.asset_path + "/images/avtar.jpeg"}
                            style={{
                                borderRadius: "19px",
                                border: "2px solid black",
                            }}
                        />
                    )}
                </div>
                <span className="tab-text">{portfolio_name}</span>
            </div>,
            sevenDays?`${sevenDays}%`: "N/A",
            thirtyDays?`${thirtyDays}%`: "N/A",
            sixMonths?`${sixMonths}%`: 'N/A',
            <div key={sixMonths} className="d-flex m-auto align-items-center">
                <button
                    className="btn btn-outline-primary rounded-pill mx-3 d-flex align-items-center analysis-btn"
                    onClick={() =>
                        navigate(`../portfolio/${portfolio_name.replace(/ /g, "-")}/detailed-portfolio-analysis`)
                    }
                >
                    <img
                        alt="Analysis"
                        src={`${window.constants.asset_path}/images/analysis.png`}
                        className="me-2 analysis-icon"
                        style={{width: 30, height: 30, borderRadius: 19}}
                    />
                    Analysis
                </button>
            </div>,
        ]
    );

    const handleSort = (column) => {
        const newSortOrder = sortOrder[column] === "asc" ? "desc" : "asc";
        setSortOrder((prevSortOrder) => ({...prevSortOrder, [column]: newSortOrder}));

        const sortMapping = {
            portfolio_name: newSortOrder === "asc" ? "pn_desc" : "pn_asc",
            seven_day: newSortOrder === "asc" ? "sd_desc" : "sd_asc",
            thirty_day: newSortOrder === "asc" ? "td_desc" : "td_asc",
            six_month: newSortOrder === "asc" ? "sm_desc" : "sm_asc",
        };

        setSortKey(sortMapping[column]);

        setHeader((prevHeader) =>
            prevHeader.map((h) =>
                h.key === column ? {...h, sorting: newSortOrder} : h.sorting ? {...h, sorting: "asc"} : h
            )
        );
    };

    const parseForecastPrice = (price) => parseFloat(price.replace(/[^0-9.-]+/g, ""));

    useEffect(() => {
        setSortType(sortKey);
    }, [sortKey]);

    useEffect(() => {
        setMyPortfolio(getSortedData());
    }, [sortType]);

    const getSortedData = useCallback(() => {
        const sortingFunctions = {
            pn_asc: (a, b) => a.portfolio_name.localeCompare(b.portfolio_name),
            pn_desc: (a, b) => b.portfolio_name.localeCompare(a.portfolio_name),
            sd_asc: (a, b) => parseForecastPrice(a.seven_day_roi) - parseForecastPrice(b.seven_day_roi),
            sd_desc: (a, b) => parseForecastPrice(b.seven_day_roi) - parseForecastPrice(a.seven_day_roi),
            td_asc: (a, b) => parseForecastPrice(a.thirty_day_roi) - parseForecastPrice(b.thirty_day_roi),
            td_desc: (a, b) => parseForecastPrice(b.thirty_day_roi) - parseForecastPrice(a.thirty_day_roi),
            sm_asc: (a, b) => parseForecastPrice(a.six_month_roi) - parseForecastPrice(b.six_month_roi),
            sm_desc: (a, b) => parseForecastPrice(b.six_month_roi) - parseForecastPrice(a.six_month_roi),
        };

        return [...myPortfolio].sort(sortingFunctions[sortType]);
    }, [myPortfolio, sortType]);

    return (
        <div className="section-3 my-5 pt-5">
            <div className="profile-headings mt-5">
                <h1>PORTFOLIO WATCHLIST</h1>
            </div>
            {!loading && myPortfolio.length === 0 && (
                <div className="add-crypto-watchlist-button d-flex justify-content-center mt-3">
                    <button className="d-flex align-items-center" onClick={() => navigate("/portfolio-gallery")}>
                        <span>ADD MORE PORTFOLIOS</span>
                        <img
                            src="images/profile_image/account_image/left-arrow-icon.svg"
                            className="left-arrow-icon"
                            alt="Add More Portfolio"
                        />
                    </button>
                </div>
            )}
            <TableHook
                header={header.map((h) => ({
                    ...h,
                    icon: h?.sorting ? (h.sorting === "asc" ? "↑" : "↓") : null,
                    onClick: h?.sorting ? () => handleSort(h.key) : undefined,
                }))}
                tableData={row}
                loading={loading}
            />
            {myPortfolio.map((portfolio) => (
                <TableCardHook
                    key={portfolio.portfolio_name}
                    header={{
                        name: portfolio.portfolio_name,
                        icon: portfolio?.photo? portfolio.photo : '/images/avtar.jpeg'
                        }}
                    body={[
                        {key: "Last 7 Days", value: portfolio.thirty_day_roi ? `${portfolio.seven_day_roi}%`: "N/A"},
                        {key: "Last 6 Months", value: portfolio.six_month_roi ? `${portfolio.six_month_roi}%` : "N/A"},
                        {key: "Last 30 Days", value: portfolio.thirty_day_roi ? `${portfolio.thirty_day_roi}%` : "N/A"}
                    ]}
                    footer={[
                        {
                            label: "Analysis",
                            icon: "analysis-icon-2.svg",
                            link: `../portfolio/${portfolio.portfolio_name.replace(
                                / /g,
                                "-"
                            )}/detailed-portfolio-analysis`,
                        },
                        {
                            label: "Recommendations",
                            icon: "start-fill-blue-icon.svg",
                            badge: portfolio.badge,
                            link: `../portfolio/${portfolio.portfolio_name.replace(/ /g, "-")}/recommendations`,
                        },
                    ]}
                />
            ))}
            {loading &&
                Array.from({length: 3}).map((_, index) => (
                    <TableCardHook
                        key={index}
                        header={{
                            name: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />,
                            icon: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} width={40} />,
                        }}
                        body={[
                            {
                                key: "Last 7 Days",
                                value: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />,
                            },
                            {
                                key: "Last 6 Months",
                                value: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />,
                            },
                            {
                                key: "Last 30 Days",
                                value: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />,
                            },
                        ]}
                        footer={[{label: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />}]}
                        loading={loading}
                    />
                ))}
            {photoGalleryLoader && myPortfolio.length > 0 && (
                <div className="d-flex justify-content-center">
                    <LoadingButton
                        loading={addMoreLoader}
                        loadingPosition="center"
                        variant="outlined"
                        style={{
                            maxWidth: "360px",
                            width: "80vw",
                            backgroundColor: "rgba(67, 97, 238, 0.15)",
                            borderRadius: "6px",
                            border: "none",
                            textTransform: "none",
                            borderStyle: "solid",
                            borderColor: "rgba(67, 97, 238, 0.15)",
                            borderWidth: "1px",
                            color: "#4361ee",
                            marginBottom: "80px",
                        }}
                        onClick={loadMoreGallery}
                    >
                        <span style={{color: "#4361ee"}}>Load More</span>
                    </LoadingButton>
                </div>
            )}
        </div>
    );
};

export default MyPortfolioWatchlist;
